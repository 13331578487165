/*
Template Name: Admin Press Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/

/*============================================================== 
 For Laptop & above all (1650px) 
 ============================================================== */

@media (min-width: 1650px) {
}

/*============================================================== 
 For Laptop & above all (1370px) 
 ============================================================== */

@media (max-width: 1370px) {
}

/*-- ============================================================== 
 Small Desktop & above all (1024px) 
 ============================================================== */

@media (min-width: 1024px) {
  .contact4 {
    .contact-box {
      padding: 80px 105px 80px 0px;
    }
  }
  .hover-dropdown .navbar-nav {
    > .dropdown:hover {
      > .dropdown-menu {
        display: block;
        margin-top: 0px;
      }
    }
  }
  .navbar-nav {
    > .dropdown {
      .dropdown-menu {
        min-width: 210px;
        margin-top: 0px;
      }
    }
  }
  .dropdown-submenu:hover > .dropdown-menu {
    display: block;
  }
}

@media (max-width: 1023px) {
  .d-flex {
    display: block !important;
    &.no-block {
      display: flex !important;
    }
  }
  .wrap-feature30-box {
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 50%;
  }
  .wrap-feature31-box,
  .contact4,
  .form6 {
    .right-image {
      position: relative;
      bottom: -95px;
      img {
        width: 100%;
      }
    }
  }
  .contact1 {
    .detail-box {
      margin-top: 40px;
    }
    .p-r-40 {
      padding-right: 0;
    }
  }
  .contact4 {
    .contact-box {
      padding: 40px 0px 40px 0px;
    }
  }
  .contact3 {
    .c-detail {
      text-align: center;
      .m-r-20 {
        margin-right: 0;
      }
      h6 {
        margin-top: 20px;
      }
    }
    .m-l-30 {
      margin-left: 0;
    }
    .contact-box {
      margin-top: 30px;
    }
  }
  .wrap-feature34-box {
    .left-image {
      position: relative;
      top: -90px;
    }
  }
  .po-absolute {
    position: relative;
  }
  .contact2 .bg-image {
    margin-right: 15px;
    margin-left: 15px;
  }
  .topbar {
    background: $themecolor;
    position: relative;
    &.animated {
      animation-duration: 0s;
      &.slideInDown {
        -webkit-animation-name: none;
        animation-name: none;
      }
    }
  }
}

/*-- ============================================================== 
 Ipad & above all(768px) 
 ============================================================== */

@media (min-width: 768px) {
}

/*-- ============================================================== 
 Phone and below ipad(767px) 
 ============================================================== */

@media (max-width: 767px) {
  html {
    font-size: 80%;
  }
  /*This is for the Global*/
  .b-l,
  .b-r {
    border-left: 0px;
    border-right: 0px;
    border-bottom: 1px solid $border;
  }
  .img-inline {
    li.half-width {
      width: 100%;
    }
  }
  .both-space {
    margin: 10px 0;
  }
  .side-content {
    padding: 40px 20px;
  }

  .general-listing.two-part {
    li {
      width: 100%;
      display: block;
    }
  }
  /*This is for the feature 12*/
  .wrap-feature-12 .uneven-box {
    margin-top: 0px;
  }
  /*This is for the feature 16*/
  .wrap-feature-16 {
    .with-text {
      margin-left: 0px;
    }
  }
  /*This is for the feature 18*/
  .wrap-feature-18 .icon-position .icon-round {
    top: 157px;
    right: 30px;
  }
  /*This is for the feature 22*/
  .wrap-feature-22 .text-box,
  .wrap-feature23-box .text-box {
    padding: 20px 0px;
  }
  .wrap-feature30-box {
    position: relative;
  }
  .contact3 {
    .p-l-0 {
      padding-left: 20px;
    }
  }
  .contact4 {
    .contact-box {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .modal2 {
    .modal-bg {
      padding: 30px;
    }
  }
  .modal3 {
    .modal-bg {
      padding: 50px 40px 60px;
    }
  }
  .modal4 {
    .modal-bg {
      padding: 40px;
    }
  }

  .dropdown-submenu > .dropdown-menu.show {
    display: block;
  }
}
