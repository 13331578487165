@import '../variable';

/*******************
topbar color
*******************/
.topbar.fixed-header {
    background: $dark;
}

/*******************
Static slide 10
*******************/
.static-slider-head{
    min-height: 600px; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: auto;
    background-size:cover;
    background-position: center center;
    .title{
        color:$white;
        font-weight: 700;
        font-size: 70px;
        line-height: 100px;
    }
    .subtitle{
        color:$white;
        line-height: 30px;
    }
}
/*******************
Header 1
*******************/

.h1-nav {
    padding: 15px 0;
    .navbar-nav .nav-item {
        margin: 0 20px;
        .nav-link {
            padding: 12px 0px;
            color: $muted;
            font-weight: 400;
            &:hover {
                color: $success;
            }
        }
        &:last-child {
            margin-right: 0px;
        }
    }
}
/*******************
Header 2
*******************/

.h2-nav {
    padding: 15px 0;
    .navbar-nav .nav-item {
        margin: 0 20px;
        .nav-link {
            padding: 12px 0px;
            color: $white;
            font-weight: 400;
            &:hover {
                color: $info;
            }
        }
        &:last-child {
            margin-right: 0px;
        }
    }
}


/*******************
Static slide 3
*******************/

.static-slider3{
    padding: 10% 0;
    h1{
        font-weight: 300;
        line-height: 50px;
        span{
            border-bottom:3px solid $success;
        }
    }
    
}
@media(max-width:767px) {
.static-slider3{    
        h1{
            line-height: 30px;
            font-size:24px;
        } 
    }
}

/*******************
Feature 4
*******************/

.wrap-feature4-box {
    .icon-round {
        font-size: 45px;
        width: 80px;
        color: $themecolor;
        line-height: 80px;
        text-align: center;
        display: inline-block;
        margin: 20px 0 30px;
        border-radius: 100%;
    }
}

/*******************
Feature 20
*******************/
.wrap-feature-20 {
    margin-top: 60px;
    [class*=col-lg-6] {
        .card{
            overflow: hidden;
        }
    }
    .linking {
        width: 100%;
        display: block;
        padding:35px 0;
    }
}

/*******************
Feature 30
*******************/
.feature30{
    position: relative;
}
.wrap-feature30-box{
    position: absolute;
    top:20%;
    right: 0px;
}

/*******************
Portfolio 1
*******************/
.img-ho{
    overflow:hidden;
    img{
        transition:0.1s ease-in;
        &:hover{
            transform:scale(1.1);
        }
    }
}  

/*******************
Pricing 8
*******************/
.pricing8{
    .pricing-box{
        sup{
            top:-20px;
            font-size: 16px;
        }
        .btn{
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
        }   
    }
}

/*******************
Blog homepage 2
*******************/
  
.blog-home2 {
    [class*=col-] {
          transition: 0.2s ease-in;
          &:hover {
              transform: translateY(-10px);
          } 
      }
    .date-pos{
        padding: 10px;
        display: inline-block;
        position: absolute;
        right:10px;
        top:10px;
        color:$white;
        border-radius: $radius;
        text-align: center;
        span{
            display: block;
            font-size: 30px;
            line-height: 30px;
        }
    }
}

/*******************
Responsive
*******************/
@media(max-width:1024px) {
    .static-slider10{
        .title{
                font-size:60px;
                line-height: 60px;
            }
    }
    .topbar {
        background: $dark;
    }

}